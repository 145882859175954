import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LastUpdated from "../components/LastUpdated/LastUpdated"
import Icon from "../components/Utilities/Icon"
import SubPageMenu from '../components/Utilities/SubPageMenu'

import styles from "../components/DetailedStats/DetailedStatsPageBlock.module.css"
import statWinner from "../js/statWinner"

class IndividualAwards extends React.Component {

  render() {

    return (

      <Layout>
        <SEO
          title={"Individual Awards - Messi vs Ronaldo All Awards and Achievements"}
          description={`Who has won more Ballon d'Or awards? Messi or Ronaldo? What about Golden Boots or Puskas Awards?`}
          canonicalPath={`/individual-awards/`}
        />

        <h1>Individual Awards <span className="sr-only">- Messi vs Ronaldo</span></h1>

        

        <SubPageMenu
          pages={[
            { 'name': 'Team', 'url': '/honours-and-achievements/' },
            { 'name': 'Individual', 'url': 'none', 'active': true },
          ]}
        />

        <div className="flex flex-wrap max-w-2xl mx-auto justify-center p-4">

          {this.props.data.allSheetHonours.edges.filter(d => d.node.type === "major" && d.node.awardType === "individual").map(d => (

            <article key={d.node.id} className={`flex w-full text-center my-4 sm:my-5 lg:my-6 xl:my-8 sm:w-1/2 lg:w-1/3 xs:px-4 md:px-6 lg:px-4 xl:px-6`}>
              <div className={`flex flex-col w-full`}>
                <h2 className="uppercase tracking-wider font-bold text-lg 2xl:text-xl">
                  {d.node.honour}
                  {d.node.honour === "Total Trophies" ? (
                    <span className="block text-xs text-gray-600 font-light">Overall career titles</span>
                  ) : ''}
                </h2>
                <div className="flex flex-wrap flex-1 mt-3">

                  <div className="flex flex-col w-1/2 border-r border-solid border-noir">
                    <h3 className="text-xs pt-1 pb-2 tracking-wider bg-noir-lighten10 text-messi uppercase">Messi</h3>
                    <div className={`p-2 bg-messi ${styles.mainStat}`}>
                      <span className="inline-block text-3xl 2xl:text-4xl font-bold relative">
                        {d.node.mcount}
                        {statWinner(Number(d.node.mcount), Number(d.node.rcount)) === 'm' ? <Icon iconName="icon-star" /> : ''}
                      </span>
                    </div>
                    <div className={`flex flex-wrap content-start flex-auto text-xs pl-1 pt-1 bg-noir-lighten10 relative text-gray-500`}>
                      {d.node.myears ? d.node.myears.split(', ').map(year =>
                        <span key={year} className={`w-full text-xs px-2 pt-1 pb-2 mr-1 mb-1 bg-noir-lighten5 text-gray-500`}>{year}</span>
                      ) : ''}
                    </div>
                  </div>

                  <div className="flex flex-col w-1/2 border-l border-solid border-noir">
                    <h3 className="text-xs pt-1 pb-2 tracking-wider bg-noir-lighten10 text-ronaldo uppercase">Ronaldo</h3>
                    <div className={`p-2 bg-ronaldo ${styles.mainStat}`}>
                      <span className="inline-block text-3xl 2xl:text-4xl font-bold relative">
                        {d.node.rcount}
                        {statWinner(Number(d.node.mcount), Number(d.node.rcount)) === 'r' ? <Icon iconName="icon-star" /> : ''}
                      </span>
                    </div>
                    <div className={`flex flex-wrap content-start flex-auto text-xs pl-1 pt-1 bg-noir-lighten10 relative text-gray-500`}>
                      {d.node.ryears ? d.node.ryears.split(', ').map(year =>
                        <span key={year} className={`w-full text-xs px-2 pt-1 pb-2 mr-1 mb-1 bg-noir-lighten5 text-gray-500`}>{year}</span>
                      ) : ''}
                    </div>
                  </div>

                  {d.node.honour === "UEFA Super Cup" ? ( <p className="w-full text-xs text-gray-700 mt-2">* did not feature in squad</p> ) : '' }

                  {d.node.honour === "Domestic Super Cup" ? ( <p className="w-full text-xs text-gray-700 mt-2">* did not feature in squad / † unused sub</p> ) : '' }

                  {/* {d.node.honour === "Non-Senior International" ? ( <p className="w-full text-xs text-gray-700 mt-2">* not counted among senior honours</p> ) : '' } */}

                  {d.node.honour === "Ballon d'Or" ? ( <p className="w-full text-xs text-gray-700 mt-2">* merged with FIFA world player of the year</p> ) : '' }

                </div>
              </div>
            </article>
          ))}


          <div className={`flex flex-wrap w-full mt-6 md:mt-8 lg:mt-10 max-w-sm`}>
            <h2 className={`w-full mb-4 md:mb-2 lg:mb-0 uppercase tracking-wider font-bold text-lg 2xl:text-xl text-center`}>Other Individual Awards &amp; Achievements</h2>

            <article className={`w-full pr-1 md:p-4 lg:p-6`}>
              <h3 className={`uppercase text-sm mb-3 flex justify-between`}>
                <span className="text-messi">Lionel Messi <span className={`sr-only`}>Other Individual Awards</span></span>
                <span className="text-ronaldo">Cristiano Ronaldo <span className={`sr-only`}>Other Individual Awards</span></span>
              </h3>
              {this.props.data.allSheetHonours.edges.filter(d => d.node.type !== "major" && d.node.type !== "hide" && d.node.awardType === "individual").map(d => (
                <div key={d.node.id} className={`relative flex flex-wrap mb-3 bg-noir-lighten10`}>
                  <h4 className={`w-full text-sm md:text-base text-gray-300 leading-snug text-center px-12 pt-3 pb-4 font-semibold bg-gray-800`}>{d.node.honour}</h4>
                  <div className="w-full flex justify-between">
                    <div className="w-1/2 p-4 md:p-5 border-r border-gray-800">
                      <p className={`text-xl font-bold absolute top-0 left-0 px-3 py-2 bg-messi ${d.node.mcount === '-' ? 'opacity-30' : ''}`}>{d.node.mcount}</p>
                      <p className={`w-full text-xs md:text-sm text-gray-500 flex flex-wrap justify-end`}>
                        {d.node.myears ? d.node.myears.split(', ').map(year =>
                          <span key={year} className="mr-2">{year}</span>
                        ) : ''}
                      </p>
                    </div>
                    <div className="w-1/2 p-4 md:p-5">
                      <p className={`text-xl font-bold absolute top-0 right-0 px-3 py-2 bg-ronaldo ${d.node.rcount === '-' ? 'opacity-30' : ''}`}>{d.node.rcount}</p>
                      <p className={`w-full text-xs md:text-sm text-gray-500 flex flex-wrap`}>
                        {d.node.ryears ? d.node.ryears.split(', ').map(year =>
                          <span key={year} className="mr-2">{year}</span>
                        ) : ''}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </article>

          </div>

        </div>

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export const query = graphql`
  query {
    allSheetHonours {
      edges {
        node {
          id
          honour
          mcount
          myears
          rcount
          ryears
          type
          awardType
        }
      }
    }
  }
`

export default IndividualAwards
